import { KeyboardEvent, ReactNode, useCallback } from 'react';
import { Icon } from '../icon/Icon';
import { keyDownHelper } from '../../helpers/keyDownHelper';
import { getClassNames } from '../../helpers/classHelpers';
import { AdaptiveIconProps, IconTheme } from '../icon/Icon.types';
import styles from './Card.module.scss';

type CardProps = {
    className?: string;
    icon?: Partial<AdaptiveIconProps> & Required<Pick<AdaptiveIconProps, 'iconName'>>;
    title?: string;
    description?: string;
    rightSide?: ReactNode;
    dataAttributes?: Record<string, string>;

    isDisabled?: boolean;
    isActive?: boolean;
    onClick?: (card: Exclude<CardProps, 'onClick'>) => void;
};

const Card = ({ onClick, className, dataAttributes, isDisabled, ...props }: CardProps) => {
    const { icon, description, isActive, title, rightSide } = props ?? {};

    const onClickCard = useCallback(() => onClick?.(props), [onClick, props]);

    const handleKeyDown = useCallback((e: KeyboardEvent<HTMLElement>) => onClick && keyDownHelper(e, onClickCard), [onClick, onClickCard]);

    return <div tabIndex={!!onClick ? 0 : undefined} {...dataAttributes} className={getClassNames([className, styles.card, isActive && styles.selected, onClick && styles.clickable, isDisabled && styles.disabled])} onKeyDown={handleKeyDown} onClick={onClickCard}>
        {icon && <div>
            <Icon.Adaptive size={42} theme={IconTheme.accent} {...icon} title='' />
        </div>}

        {(title || description) && <div className={styles.info}>
            {title && <b>{title}</b>}
            {description && <p className={styles.description}>{description}</p>}
        </div>}

        {rightSide && <div>
            {rightSide}
        </div>}
    </div>;
};

export default Card;

