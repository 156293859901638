import ParserInline from 'markdown-it/lib/parser_inline';
import Renderer, { RenderRule } from 'markdown-it/lib/renderer';
import Ruler from 'markdown-it/lib/ruler';
import parse from 'html-react-parser';
import katex from 'katex';
import markdownTextMath from 'markdown-it-texmath';
import { ErrorLabels, MarkdownItWithIncrementalDOM } from '../../models/types';
import { renderDfAlert } from '../../helpers/mosaicHTMLRenderHelpers';
import { isString } from '../../helpers/typeHelpers';
import { TABLE_WRAPPER_CLASS_NAME } from './hooks/useTableSearch/useTableSearch';
import { promptErrorTagRegex, trustedHtmlTagTagRegex } from '../../helpers/messageStreamHelper';
import { getClassNames } from '../../helpers/classHelpers';
import { danfossAssetsBaseUrl } from '../../constants/consts';

const resizableImageFormats = ['.jpg', '.jpeg', '.gif'];

export function addMarkdownDetectionRule(md: MarkdownItWithIncrementalDOM, ruleName: string, detectionRule: ParserInline.RuleInline, ruleOptions?: Ruler.RuleOptions) {
    try {
        md.inline.ruler.at(ruleName, detectionRule, ruleOptions);
    } catch (e: unknown) {
        if (e instanceof Error) {
            const parserRuleNotFound = e?.message?.includes('Parser rule not found:');
            if (parserRuleNotFound) md.inline.ruler.push(ruleName, detectionRule, ruleOptions);
        }
    }
}
export function addMarkdownRendererRule(md: MarkdownItWithIncrementalDOM, ruleName: string, renderRule: RenderRule) {
    md.renderer.rules[ruleName] = renderRule;
}


export function addMarkdownDetectionAndRenderRule(markdownitInstance: MarkdownItWithIncrementalDOM, ruleName: string, detectionRule: ParserInline.RuleInline, renderRule: RenderRule, ruleOptions?: Ruler.RuleOptions) {
    // Rule to detect the custom tag
    addMarkdownDetectionRule(markdownitInstance, ruleName, detectionRule, ruleOptions);

    // Rule to render the custom tag
    addMarkdownRendererRule(markdownitInstance, ruleName, renderRule);
}

export function containsMarkdownErrorTag(markdown: unknown) {
    if (!isString(markdown)) return null;
    return markdown.match(promptErrorTagRegex);
}

export function containsMarkdownTrustedHtmlTag(markdown: unknown) {
    if (!isString(markdown)) return null;
    return markdown.match(trustedHtmlTagTagRegex);
}

export function setupMarkdownRule({
    md,
    tag,
    ruleName,
    getMatches,
    renderTag,

}: {
    md: MarkdownItWithIncrementalDOM;
    ruleName: string;
    tag: keyof HTMLElementTagNameMap;
    renderTag: RenderRule;
    getMatches: (content: string) => RegExpMatchArray | null;

}) {
    const detectTag: ParserInline.RuleInline = (state, silent) => {
        // Check for "<" and early return if not found. Saves RegExp check
        if (state.src.charCodeAt(state.pos) !== 0x3c) return false;

        // Check for full error tag using RegExp
        const currentContent = state.src.slice(state.pos);
        const match = getMatches(currentContent);

        // Return if no match was found
        if (!match) return false;

        // If not silent, we push content to token to be processed
        // in the md.renderer.rules['RULE_NAME'] function
        if (!silent) {
            const token = state.push(ruleName, tag, 0);
            // We are only interested in the content of the error tag, not the tags themselves
            const errorContent = match[1];
            // Return if no content was found
            if (!errorContent) return false;
            // Push content to token
            token.content = match[1];
        }

        // Change the position of the parser to the end of the error tag
        state.pos += match[0].length;

        // Return true to indicate the token was handled
        return true;
    };

    return addMarkdownDetectionAndRenderRule(md, ruleName, detectTag, renderTag);
}

export function setupErrorMarkdownRules<ErrorKey extends string>(md: MarkdownItWithIncrementalDOM, errorsDict?: ErrorLabels<ErrorKey>, defaultError?: ErrorKey) {
    const ruleName = 'custom-error';

    const renderTag: RenderRule = (tokens, idx) => {
        const token = tokens[idx];
        const errorsLabels = errorsDict ?? {} as ErrorLabels<ErrorKey>;
        const defaultErrorKey = defaultError ?? "" as ErrorKey;
        try {
            const content: { message: string; code: string; } = JSON.parse(token.content);

            if (!content.code) throw new Error('Parsed error json did not contain message or code');
            const defaultError = errorsLabels[defaultErrorKey];
            const error = content.code !== defaultError ? errorsLabels[content.code as ErrorKey] : undefined;
            return renderDfAlert(error?.h ?? defaultError?.h ?? "Error", error?.d ?? `${defaultError?.d ?? ""} (${content.message ?? "-"})`.trim(), 'pt-2');
        } catch (error) {
            console.error('Something went wrong when parsing this error', error);
            return renderDfAlert('Parsing error occurred', 'We apologize for any inconvenience this may have causes. Please try again later.');
        }
    };

    return setupMarkdownRule({
        md,
        tag: 'df-alert',
        ruleName,
        getMatches: containsMarkdownErrorTag,
        renderTag,
    });
}
export function setupTrustedHtmlRules(md: MarkdownItWithIncrementalDOM) {
    const ruleName = 'custom-trusted-html';

    const renderTrustedHtml = (html?: string) => html ? `<div class="trusted-html">
            ${parse(html)}
        </div>` : '';

    const renderTag: RenderRule = (tokens, idx) => {
        const token = tokens[idx];

        try {
            return renderTrustedHtml(token.content);
        } catch (error) {
            console.error('Something went wrong when parsing this error', error);
            return renderDfAlert('Parsing error occurred', 'We apologize for any inconvenience this may have causes. Please try again later.');
        }
    };

    return setupMarkdownRule({
        md,
        tag: 'div',
        ruleName,
        getMatches: containsMarkdownTrustedHtmlTag,
        renderTag
    });
}



// Ensures that links open in a new tab
export const linkOpenRenderOverride: Renderer.RenderRule = (tokens, idx) => {
    //let target = "_blank";
    const attributesString = (tokens[idx].attrs || [])
        .map(([key, value]: string[]) => {

            if (key === 'href') {
                // If link is to "#" or empty, remove the href attribute and style as
                // regular <span>. Styled with "a:not([href])" in css ('./markdown.scss')
                if (!value || value === '#') {
                    return '';
                }

                // If the link is not an absolute link, add the http prefix
                // to ensure it will not open relative to the current page
                if (value && !['http', '/', '#'].some(prefix => value.startsWith(prefix))) {
                    value = `http://${value}`;
                }

                // // TODO: handle the case when there is a query param at the end of the PDF URL
                // // For example https://assets.danfoss.com/documents/359566/AB440847594423en-000103.pdf#page=5
                // // Could also be a query param
                // // The file fileType check fails in the question
                // const fileType = WHITELISTED_FILE_FORMATS.find(f => value!.split('?')[0].endsWith(f));
                // if (value.startsWith(danfossAssetsBaseUrl) && fileType) {
                //     value = "#" + createAssetActionLink(value, fileType as ObjectTypes);
                //     //target = "_self";
                // }
                // else if (value.startsWith("#")) { // TODO: also check if it is a valid side panel link
                //     //target = "_self";
                // }
            }

            return `${key}="${value}"`;
        });
    //target="${target}" 
    return `<a rel="noopener noreferrer" ${attributesString}>`;
};
export function addLinkOpenRenderOverride(md: MarkdownItWithIncrementalDOM) {
    addMarkdownRendererRule(md, 'link_open', linkOpenRenderOverride);
}
export const linkCloseRenderOverride: Renderer.RenderRule = () => '</a>';
export function addLinkCloseRenderOverride(md: MarkdownItWithIncrementalDOM) {
    addMarkdownRendererRule(md, 'link_close', linkCloseRenderOverride);
}


// Adds Mosaic table class to tables
// Adds overflow-x-scroll class to tables - class is in `Source/src/index.css`
// Also add additional class to table container so it could be targeted for table search functionality
export const TABLE_OVERFLOW_CLASS_NAME = 'overflow-x-scroll';
// Needed for overflowing the menu to the left of the table
export const TABLE_MENU_CLASS_NAME = 'menu-wrapper';
export const tableOpenRenderOverride: Renderer.RenderRule = () => `<div class="${TABLE_WRAPPER_CLASS_NAME}"><div class="${TABLE_MENU_CLASS_NAME}"><div class="${TABLE_OVERFLOW_CLASS_NAME}"><table class="df-table">`;
export function addTableOpenRenderOverride(md: MarkdownItWithIncrementalDOM) {
    addMarkdownRendererRule(md, 'table_open', tableOpenRenderOverride);
}


export const tableCloseRenderOverride: Renderer.RenderRule = () => '</table></div></div>';
export function addTableCloseRenderOverride(md: MarkdownItWithIncrementalDOM) {
    addMarkdownRendererRule(md, 'table_close', tableCloseRenderOverride);
}

export const IMAGE_WRAPPER_CLASS_NAME = 'markdown-image-container';
const supportedImageFormatRegExp = /\.(png|jpg|jpeg|webp)(?=\?|#|=|$)/;
const dimensionsRegExp = /#(\d+)?x(\d+)?$/;
export const imageRenderOverride: (imageBaseUrl?: string) => Renderer.RenderRule = (imageBaseUrl) => (tokens, idx, options, env, self) => {
    const token = tokens[idx];

    const alt = token.content;
    let src = token.attrGet("src") || '';

    // Apply image resize (optimization for API asset scaling) 
    if (src && src.startsWith(danfossAssetsBaseUrl) && resizableImageFormats.some(f => src!.endsWith(f))) {
        src += "?width=600&height=300&mode=max";
    }

    // Apply base url to relative paths
    if (src?.startsWith('.')) {
        src = src.substring(1);
        if (!src.startsWith('/')) src = `/${src}`;
    }
    if (src?.startsWith('/')) {
        src = `${imageBaseUrl ?? window.location.origin}${src}`;
    }

    // Parse dimensions from the alt text if present
    const dimensions = src.match(dimensionsRegExp);
    let width;
    let height;
    let error = false;

    // Check if the image is of a supported type
    if (!src.match(supportedImageFormatRegExp)) {
        error = true;
    }

    // Attach dimensions to the image
    if (dimensions) {
        width = dimensions[1] || '';
        height = dimensions[2] || '';
        src = src.replace(dimensionsRegExp, '').trim();
    }

    // Construct the image HTML tag
    let imgHtml = `<img class='markdown-image' src="${src}" alt="${alt}"`;
    if (width) imgHtml += ` width="${width}"`;
    if (height) imgHtml += ` height="${height}"`;
    imgHtml += '/>';

    return `<span class='${getClassNames([IMAGE_WRAPPER_CLASS_NAME, error && 'image-load-error'])}'>${imgHtml}</span>`;
};
export function addImageProcessor(md: MarkdownItWithIncrementalDOM, imageBaseUrl?: string) {
    addMarkdownRendererRule(md, 'image', imageRenderOverride(imageBaseUrl));
}


type ErrorKey = string;
export function initializeMarkdownIt({ errorMessageDict, defaultError, imageBaseUrl }: { errorMessageDict?: ErrorLabels<ErrorKey>; defaultError?: ErrorKey; imageBaseUrl?: string; }) {
    // Declared in `Source/src/models/types.ts`
    const md = window.markdownit({
        linkify: true,

        highlight: function (str: any, lang: any) {
            if (lang && window.hljs.getLanguage(lang)) {
                try {
                    return window.hljs.highlight(str, { language: lang }).value;
                } catch (__) {}
            }

            return ''; // use external default escaping
        }
    })
        .use(markdownTextMath, {
            engine: katex,
            delimiters: 'brackets',
            katexOptions: { throwOnError: false }
        })
        .use(window.markdownitIncrementalDOM, window.IncrementalDOM) as MarkdownItWithIncrementalDOM;

    addTableOpenRenderOverride(md); // Apply mosaic table class
    addTableCloseRenderOverride(md); // Apply mosaic table class
    addLinkOpenRenderOverride(md); // Add target="_blank" to links
    addLinkCloseRenderOverride(md); // In some cases the markdown renderer crashes if we do not explicitly set the link_close rule
    addImageProcessor(md, imageBaseUrl); // Add image resize params for images from assets.danfoss.com
    setupTrustedHtmlRules(md);
    setupErrorMarkdownRules(md, errorMessageDict, defaultError);
    return md;
}


// Async markdown wrapper = pass url in, handles: download and load/error/render
