import useStateRef from '../../hooks/useStateRef';
import { useCallback, useEffect } from 'react';
import { BackendEvent, BackendEventTypes, SessionEvent, SessionEventTypes } from '../../models/BackendEvents';
import { useChat } from '../../contexts/chat/ChatContext';

const WHITELISTED_SESSION_EVENTS = [SessionEventTypes.updated, SessionEventTypes.documentAdded, SessionEventTypes.documentRemoved, SessionEventTypes.deleted] as BackendEventTypes[];

const getRelevantEvents = <T extends { type: BackendEventTypes; }[]>(events: T) => {
    const filteredEvents = events?.filter((event) => WHITELISTED_SESSION_EVENTS.includes(event.type) && Object.values((event as any)?.metadata).length);
    return (filteredEvents ?? []) as BackendEvent[];
};

const useSessionEvents = ({ scrollToBottomWithDelay }: { scrollToBottomWithDelay?: () => void; }) => {
    const { events: { session: sessionEvents } } = useChat();

    const [events, setEvents] = useStateRef<BackendEvent[]>([]);
    const [documents, setDocuments] = useStateRef<string[]>([]);

    const onSessionEvent = useCallback((model: SessionEvent) => {
        const events = getRelevantEvents([JSON.parse(model as any as string)]);

        if (events?.length) {
            setEvents((prev) => [...prev, ...events]);

            setDocuments((prev) => {
                const updatedDocuments = [...prev];
                events.forEach(event => {
                    const { type, metadata } = event as SessionEvent;

                    if (type === SessionEventTypes.documentAdded) {
                        metadata.documents?.forEach((document_id: string) => {
                            updatedDocuments.push(document_id);
                        });
                    }
                    if (type === SessionEventTypes.documentRemoved) {
                        metadata.documents?.forEach((document_id: string) => {
                            updatedDocuments.splice(updatedDocuments.indexOf(document_id), 1);
                        });
                    };
                });

                return updatedDocuments;
            });

            scrollToBottomWithDelay?.();
        }
    }, [scrollToBottomWithDelay, setDocuments, setEvents]);

    useEffect(() => {
        const events = sessionEvents;
        events.on(onSessionEvent);
        return () => events.off(onSessionEvent);
    }, [sessionEvents, onSessionEvent]);

    const handleSetEvents = useCallback((events: BackendEvent[]) => setEvents(getRelevantEvents(events)), [setEvents]);

    return { events, documents, setEvents: handleSetEvents, setDocuments };
};

export default useSessionEvents;