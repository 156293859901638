import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize } from '../../constants/consts';
import { useLocation } from 'react-router-dom';

export type RightSidebarProps = {
    header?: React.ReactNode;
    children?: React.ReactNode;
    metadata?: Record<string, string>;
};

export type LayoutContextProps = {
    isLeftSidebarVisible: boolean,
    showLeftSidebar: () => void;
    hideLeftSidebar: () => void;

    isRightSidebarVisible: boolean;
    showRightSidebar: () => void;
    hideRightSidebar: () => void;
};

const defaultLayout = {
    isLeftSidebarVisible: false,
    showLeftSidebar: () => {},
    hideLeftSidebar: () => {},

    isRightSidebarVisible: false,
    showRightSidebar: () => {},
    hideRightSidebar: () => {}
};

const LayoutContext = createContext(defaultLayout as LayoutContextProps);

export const LayoutProvider = (props: PropsWithChildren<{}>) => {
    const location = useLocation();

    const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(false);
    const [isLeftSidebarVisible, setIsLeftSidebarVisible] = useState(false);

    const showLeftSidebar = useCallback(() => setIsLeftSidebarVisible(true), []);
    const hideLeftSidebar = useCallback(() => setIsLeftSidebarVisible(false), []);

    const showRightSidebar = useCallback(() => setIsRightSidebarVisible(true), []);

    const hideRightSidebar = useCallback(() => {
        setIsRightSidebarVisible(false);
    }, []);

    const isTabletSize = useMediaSize(ms => ms >= MediaSize.md);
    const autoPresetLeftSidebar = useMemo(() => !isRightSidebarVisible && isTabletSize, [isRightSidebarVisible, isTabletSize]);

    const value = useMemo(() => ({
        isLeftSidebarVisible: isLeftSidebarVisible || autoPresetLeftSidebar,
        showLeftSidebar,
        hideLeftSidebar,

        isRightSidebarVisible,
        showRightSidebar,
        hideRightSidebar
    }), [isRightSidebarVisible, autoPresetLeftSidebar, isLeftSidebarVisible, showLeftSidebar, hideLeftSidebar, showRightSidebar, hideRightSidebar]);

    // Close right sidebar upon location change
    const pathnameRef = useRef(location.pathname);
    useEffect(() => {
        if (pathnameRef.current && location.pathname === pathnameRef.current) return;
        pathnameRef.current = location.pathname;
        hideRightSidebar();
    }, [location, hideRightSidebar]);

    return <LayoutContext.Provider value={value}>
        {props.children}
    </LayoutContext.Provider>;
};

export const useLayout = () => useContext(LayoutContext);
