import React, { type PropsWithChildren } from 'react';
import MockAuthContextProvider, { AuthState } from '../auth/MockMsalAuthContextProvider';
import MockChatServiceProvider from '../chat/MockChatServiceProvider';
import MockAppInfoServiceProvider from '../app-info/MockAppInfoServiceProvider';
import MockBackendEventsServiceProvider from '../backend-events/MockBackendEventsServiceProvider';
import MockAdaptiveProvider from '../adaptive/MockAdaptiveProvider';

const MockContextBootstrap: React.FC<PropsWithChildren<{ authState?: Partial<AuthState>; }>> = ({ children, authState: authStateOverride }) => {
    return (
        <MockAuthContextProvider state={authStateOverride}>
            <MockBackendEventsServiceProvider>
                <MockAppInfoServiceProvider>
                    <MockChatServiceProvider>
                        <MockAdaptiveProvider>
                            {children}
                        </MockAdaptiveProvider>
                    </MockChatServiceProvider>
                </MockAppInfoServiceProvider>
            </MockBackendEventsServiceProvider>
        </MockAuthContextProvider>
    );
};

export default MockContextBootstrap;