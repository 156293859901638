import React, { useEffect, useState } from "react";
import { getClassNames } from '../../helpers/classHelpers';
import style from "./FileInputProgress.module.scss";

type Progress = {
    loaded?: number;
    total?: number;
};

type FileInputProgressProps = {
    hasError?: boolean;
    className?: string;
    progress?: Progress;
    onDoneLoading?: () => void;
};

const SHOW_PROGRESS_BAR_AFTER = 50;
const HIDE_PROGRESS_BAR_AFTER = 3000;

const FileInputProgress: React.FC<FileInputProgressProps> = ({ className, hasError, progress, onDoneLoading }) => {
    const [isShowing, setIsShowing] = useState(false);

    const getCurrentPercentage = (progress?: Progress) => progress ? Math.round(((progress?.loaded ?? NaN) / (progress?.total ?? NaN)) * 100) : NaN;
    const progressValue = getCurrentPercentage(progress);
    const isDoneLoading = progressValue === 100;

    useEffect(() => {
        if (!progress) return;
        const showTimeout = setTimeout(() => !isDoneLoading && setIsShowing(true), SHOW_PROGRESS_BAR_AFTER);

        let hideTimeout: NodeJS.Timeout;
        if (isDoneLoading) {
            onDoneLoading?.();
            hideTimeout = setTimeout(() => setIsShowing(false), HIDE_PROGRESS_BAR_AFTER);
        }

        return () => {
            clearTimeout(showTimeout);
            clearTimeout(hideTimeout);
        };
    }, [isDoneLoading, onDoneLoading, progress]);

    return (
        <div className={getClassNames([className, hasError && isDoneLoading && style.error, !hasError && isDoneLoading && style.success, !isShowing && style.hidden, style['file-input-progress'], "df-progress-bar"])}>
            <div
                role="progressbar"
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: progressValue + '%' }}
                aria-valuenow={progressValue}
            />
        </div>
    );
};

export default FileInputProgress;