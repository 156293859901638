import useStorage from '../../hooks/useStorage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RibbonMessageProps } from '../../ui/ribbon-message/RibbonMessage';

const MESSAGE = {
    id: 'chatbot-interface-change',
    label: 'The Danfoss AI Chatbot interface is changing. Changes are expected before the end of November. To learn more',
    urlLabel: 'click here',
    url: 'https://danfoss.sharepoint.com/sites/AI/SitePages/Danfoss-AI-Chatbot.aspx',
};

type RibbonMessage = Omit<RibbonMessageProps, 'handleClose'>;

const useRibbonMessage = (): { ribbonMessage?: RibbonMessageProps; } => {
    const [ribbonMessage, setRibbonMessage] = useState<RibbonMessage>();
    const { get, set } = useStorage(`notification`, 'session');

    const id = MESSAGE.id;
    const setMessage = (value?: RibbonMessage) => setRibbonMessage(value);

    // Should be driven by BE
    useEffect(() => {
        if (!id) return;
        const isVisible = get(id)?.visible;
        if (isVisible || isVisible === undefined) setMessage(MESSAGE);
    }, [get, id]);

    const handleClose = useCallback(() => {
        if (!id) return;
        setRibbonMessage(undefined);
        set(id, { visible: false });
    }, [id, set]);

    const props = useMemo(() => ({
        ...ribbonMessage,
        handleClose
    }), [handleClose, ribbonMessage]);

    return {
        ribbonMessage: props,
        // setRibbonMessage // TODO: Implement if needed
    };
};

export default useRibbonMessage;