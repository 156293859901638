import labels from '../../../../../../../data/labels';
import Table from '../../../../../../table/Table';
// import FilterControls from '../../../../../../../shared/filters/filter-control/FilterControls';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getInlineIcon } from '../../../../../../icon/Icon';
import { isArray, isLiteralObject, isNumber, isString } from '../../../../../../../helpers/typeHelpers';
// import { FilterConfigArgs, FilterInput, FilterType } from '../../../../../../../shared/filters/FilterController';
// import { useFilterController } from '../../../../../../../shared/filters/useFilterController';
// import { FilterOption, LinkPropertiesIcons, TableData, TableRowsResponse } from '../../../../../../../models/SidePanelTypes';
import { LinkPropertiesIcons, TableData, TableRowsResponse } from '../../../../../../../models/SidePanelTypes';
import { createAssetActionLink } from '../../../useActions';
import { Icons } from '../../../../../../icon/icons/material';
import { ErrorHandler } from '../../../../../../../contexts/error-handler/ErrorHandler';
import { useChat } from '../../../../../../../contexts/chat/ChatContext';
import { guid } from '../../../../../../../helpers/randomHelpers';
import { useErrorHandlerContext } from '../../../../../../../contexts/error-handler/ErrorContext';
import { useLov } from '../../../../../../../hooks/useLabels';
import styles from '../Data.module.scss';


const DataTable = ({ className, headers, rows, title, source, chatId }: TableData['properties'] & { className?: string; chatId?: string; }) => {
    const { tableData: getDynamicData } = useChat();
    const { errorId, getError, registerError, removeError } = useErrorHandlerContext();
    const error = useMemo(() => getError(errorId), [errorId, getError]);

    const [loadDynamicData, setLoadDynamicData] = useState<boolean>();
    const [dynamicData, setDynamicData] = useState<TableRowsResponse>();

    // const initialFilterConfig = useMemo(() => headers.filter(x => x.filterable).map(({ name }) => ({ key: name, type: FilterType.Select })), [headers]);
    // const dynamicFilterConfig = useMemo((): FilterConfigArgs<Record<string, string[] | undefined>> => {
    //     return headers.filter(x => x.filterable && x.filterConfig?.options).map(({ name, filterConfig }, i) => {
    //         return {
    //             key: name,
    //             type: filterConfig?.type as FilterType,
    //             options: filterConfig?.options?.map((value) => {
    //                 if (typeof value === 'string') return {
    //                     value: value,
    //                     id: value.toString(),
    //                 };

    //                 else if (typeof value === 'object') {
    //                     const v = value as FilterOption;
    //                     return {
    //                         value: v.value,
    //                         id: v.name,
    //                     };
    //                 }
    //                 return { value: '-', id: i.toString() };
    //             }) || []
    //         };
    //     });
    // }, [headers]);

    // const filterConfig = useMemo(() => {
    //     if (dynamicFilterConfig.length) return dynamicFilterConfig;
    //     if (initialFilterConfig.length) return initialFilterConfig;
    //     return [];
    // }, [dynamicFilterConfig, initialFilterConfig]);

    // const assets = useMemo(() => headers.map(({ name }, index) => {
    //     return {
    //         [name]: (dynamicData?.rows || rows)?.map((row) => {
    //             return processTableValue(row[index]);
    //         }) ?? []
    //     };
    // }), [dynamicData?.rows, headers, rows]);


    // const initialValue = useMemo(() => headers.reduce((acc, curr) => (
    //     [
    //         ...acc,
    //         {
    //             key: curr.name,
    //             values: curr?.filterConfig?.initialInput || [],
    //             created: Date.now(),
    //             updated: NaN,
    //         }
    //     ]), [] as FilterInput[]), [headers])

    //const { controller, filters } = useFilterController(assets, filterConfig);

    // const params = useMemo(() => controller.appliedInput.reduce((acc, curr) => {
    //     console.log({ params })
    //     acc[curr.key] = curr.values;
    //     return acc;
    // }, {} as Record<string, string[]>), [controller.appliedInput]);


    const initiallyLoadedRef = useRef(false);
    const loader = useCallback((async () => {

        if (!source || !chatId) return;
        setLoadDynamicData(true);
        removeError(errorId);

        setDynamicData(undefined);
        try {
            const data = await getDynamicData(chatId, source, undefined, {});
            setDynamicData(data);
            setLoadDynamicData(false);
        }
        catch (error: any) {
            console.error(error);
            setLoadDynamicData(false);
            registerError({ [errorId]: { type: 'notification', headline: labels.loadingErrorDescription, details: error } });
        }
        initiallyLoadedRef.current = true;
    }), [source, chatId, removeError, errorId, getDynamicData, registerError]);

    // Initial load
    useEffect(() => {
        loader();
    }, [loader]);

    const lov = useLov();
    const resolveLabel = useCallback((value: number | string, label?: string) => {
        if (!label) return value?.toString();
        return (lov as Record<string, Record<string | number, string>>)[label]?.[value];
    }, [lov]);

    if (error) return <ErrorHandler.Notification className={styles.error} id={errorId} retry={{ headline: labels.retry, onAsyncClick: loader }} />;
    if (loadDynamicData === false && !dynamicData?.rows?.length) return <ErrorHandler.Notification isStandalone alertOverride={{ type: 'info', headline: labels.noDynamicData }} />;

    return (
        <>
            {title && <><h5>{title}</h5></>}
            <Table
                className={className}
                showSkeletonWhileLoading
                // dynamicFilter={
                //     <FilterControls controller={controller} filters={filters} />
                // }
                labels={{
                    placeholder: labels.tableSearchHeadline,
                    notFoundMessage: labels.tableSearchNotResultsFound
                }}
                title={""}
                columns={
                    headers.map(({ name, sortable, searchable }) => {
                        return { id: name, Header: name, accessor: name, canBeSorted: !!sortable, isSearchable: searchable };
                    })
                }
                data={
                    (dynamicData?.rows || rows)
                        // ?.filter(row => {
                        //     if (!controller.input.length) return row;
                        //     const included = controller.input.find((x) => row.some((cell) => x.values.includes(cell)));
                        //     return included;
                        // })
                        ?.map((row) => {
                            return row.reduce((acc, curr, i) => {
                                const header = headers[i];
                                const value = processTableValue(curr, header.label, resolveLabel);
                                acc[header?.name] = value;
                                return acc;
                            }, {} as Record<string, string>);
                        })
                }
            />
        </>
    );
};

export default DataTable;

const processTableValue = (value: string | number | (string | number)[] | { text?: string, url: string, icon?: LinkPropertiesIcons; }, label?: string, resolveLabel?: (value: string | number, label?: string) => string) => {
    if (!resolveLabel)
        resolveLabel = ((v: string | Number) => v?.toString() || "");

    if (isString(value) || isNumber(value)) return resolveLabel(value, label);

    if (isArray(value)) {
        if (!value.length) return '';
        if (value.length === 1) return resolveLabel(value[0]?.toString(), label);

        const elmId = guid();
        const result: string[] = value.map(v => `<li>${resolveLabel!(v, label)}</li>`);
        return `
            <div class="collapsible">
                <input type="checkbox" class="collapsible-state" id="${elmId}" />
                <ul>
                    ${result.join('')}
                </ul>
                ${value.length > 3 ? `<label for="${elmId}" class="collapsible-cta collapsible-cta-more">...</label>` : ''}
            </div>
        `;
    }
    if (isLiteralObject(value)) {
        if (value.text) return `<a href="${value.url}" class="${styles.link}">${resolveLabel(value.text, label)}</a>`;

        switch (value.icon) {
            case 'new-tab': return `<a href="${value.url}" class="${styles['icon-link']}" target="_blank" rel="noopener noreferrer">${getInlineIcon({ iconName: Icons?.[value.icon as keyof typeof Icons] })}</a>`;
            case 'download': return `<a href="${value.url}" class="${styles['icon-link']}" target="_blank" rel="noopener noreferrer" download>${getInlineIcon({ iconName: Icons?.[value.icon as keyof typeof Icons] })}</a>`;
            case 'pdf': return `<a href="#${createAssetActionLink(value.url)}" class="${styles['icon-link']}">${getInlineIcon({ iconName: Icons?.[value.icon as keyof typeof Icons] })}</a>`;
            default: return '-';
        }
    }

    return '';
};