import Async from './Async';
import Base from './Base';
import Svg from './Svg';
import { AdaptiveIconProps, AsyncIconProps, IconProps, SvgIconProps } from '../Icon.types';
import { Icons } from '../icons/material';
import { SvgIcons } from '../icons/svg';

export default function Adaptive(props: AdaptiveIconProps) {
    if ('onAsyncClick' in props) return <Async {...props as AsyncIconProps} />;
    if (props.iconName in Icons) return <Base {...props as IconProps} />;
    if (props.iconName in SvgIcons) return <Svg {...props as SvgIconProps} />;

    return <div>Unsupported icon type</div>;
};
