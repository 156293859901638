import Card from '../../ui/card/Card';
import { getClassNames } from '../../helpers/classHelpers';
import { SvgIcons } from '../../ui/icon/icons/svg';
import styles from './ChatRecommendations.module.scss';

const ChatRecommendations = ({ recommendations, isDisabled, onClick }: { recommendations?: string[], isDisabled?: boolean, onClick: (recommendation: string) => Promise<void>; }) => {
    if (!recommendations?.length) return null;
    return (
        <div className={getClassNames([styles['chat-recommendations'], recommendations.length % 3 === 0 && styles['three-column-layout']])}>
            {recommendations.map((rec) => <Card key={rec} icon={{ iconName: SvgIcons.Robot, size: 20 }} className={styles.card} isDisabled={isDisabled} description={rec} onClick={() => onClick(rec)} />)}
        </div>
    );
};

export default ChatRecommendations;