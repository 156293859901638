import React, { useEffect, useMemo } from 'react';
import UnauthenticatedRootView from '../views/unauthenticated-root-view/UnauthenticatedRootView';
import LoggedOutTemplate from '../templates/LoggedOutTemplate';
import PageLayout from '../layouts/page-layout/PageLayout';
import Hero from '../ui/hero/Hero';
import Button from '../ui/button/Button';
import useLabels from '../hooks/useLabels';
import LoggedInTemplate from '../templates/LoggedInTemplate';
import AuthenticationStateErrorView from '../views/authentication-state-error-view/AuthenticationStateErrorView';
import Prompt from '../ui/prompt/Prompt';
import TokenStateErrorView from '../views/token-state-error-view/TokenStateErrorView';
import DcsPromo from '../views/promo/DcsPromo';
import ChatViewLayout, { ChatViewLayoutProps } from '../layouts/chat-view-layout/ChatViewLayout';
import style from './Routing.module.scss';
import ChatView from '../views/chat-view/ChatView';
import CatalogView from '../views/catalog-view/CatalogView';
import InformationView from '../views/information-view/InformationView';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationState, useAppInfo } from '../contexts/app-info/AppInfoContext';
import { TokenState, useAuth } from '../contexts/auth/AuthContext';
import { setupGlobalAnchorClickHandler } from '../helpers/hashRouting';
import { EventHandlerModel } from '../services/EventHandler';
import { advancedAssistantRoute, pimAssistantRoute } from '../constants/consts';
import { useAdaptive } from '../contexts/adaptive/AdaptiveContext';

type Props = {};


export enum DynamicRouteParams {
    chatId = 'chatId',
    objectId = 'objectId',
    objectType = 'objectType',
}

export type DynamicRouteProperties = {
    [DynamicRouteParams.chatId]?: string;
    [DynamicRouteParams.objectId]?: string;
    [DynamicRouteParams.objectType]?: string;
};

export enum AppRoute {
    root = '/',
    info = '/info',
    chat = '/chat',
    catalog = '/catalog',
    chatItem = `/chat/:chatId`,
    dcsPimPersonaPromo = '/dcs/promo'
}

// Custom persona routes allows to change persona via route
export const customPersonaRoutes = [
    { key: pimAssistantRoute },
    { key: advancedAssistantRoute }
];

export const getChatItemRoute = (chatId: string) => AppRoute.chatItem.replace(`:${DynamicRouteParams.chatId}`, chatId);

const Routing: React.FC<Props> = () => {
    const labels = useLabels();
    const { login, tokenState } = useAuth();
    const { authenticationState, personas } = useAppInfo();

    const { catalogFavorites } = useAdaptive();

    const renderChatView = (props: ChatViewLayoutProps) => <ChatView {...props} />;
    const renderCatalogView = (props: ChatViewLayoutProps) => <CatalogView {...props} />;

    const personaRoutes = useMemo(() => [...(personas ?? []), ...customPersonaRoutes]?.map(x => '/' + x.key), [personas]);

    const renderPersonaRoutes = useMemo(() => {
        return personaRoutes.map((route) => <Route key={route} path={route} element={<ChatViewLayout children={renderChatView} key='same-key' newPrompt newPromptOrigin={route.replace(/^\//, "").replace("/", "-")} />} />);
    }, [personaRoutes]);

    const renderAuthenticated = useMemo<React.ReactNode>(() => {
        return (<>
            <Routes>
                <Route path={AppRoute.info} element={<InformationView />} />
                <Route path={AppRoute.root} element={<Navigate to={catalogFavorites.firstFavorite} replace={true} />} />
                <Route path={AppRoute.chat} element={<Navigate to={'/' + catalogFavorites.firstFavorite} replace={true} />} />
                <Route path={AppRoute.dcsPimPersonaPromo} element={<DcsPromo />} />
                <Route path={AppRoute.catalog} element={<ChatViewLayout children={renderCatalogView} newPrompt key='same-key' />} />
                {renderPersonaRoutes}
                <Route path={AppRoute.chatItem + '/*'} element={<ChatViewLayout children={renderChatView} />} />
                <Route path='/*' element={<NotFound />} />
            </Routes>

            {tokenState === TokenState.RequiresInteraction && <Prompt
                className={style['token-expired-prompt']}
                isOpen={tokenState === TokenState.RequiresInteraction}
                headline={labels.tokenExpiredHeadline}
                body={labels.tokenExpiredDescription}
                closeOnBackdropClick={false}
                closeOnEscape={false}
                primaryButton={{
                    label: labels.login,
                    onClick: login,
                }}
            />}
        </>);
    }, [catalogFavorites.firstFavorite, labels.login, labels.tokenExpiredDescription, labels.tokenExpiredHeadline, login, renderPersonaRoutes, tokenState]);

    const renderLoggedIn = useMemo<React.ReactNode>(() => {
        if (
            tokenState !== TokenState.Valid &&
            authenticationState !== AuthenticationState.Authenticated
        ) return <TokenStateErrorView />;
        else {
            if (authenticationState === AuthenticationState.Authenticated) return renderAuthenticated;
            else return <AuthenticationStateErrorView />;
        }
    }, [authenticationState, renderAuthenticated, tokenState]);

    const renderLoggedOut = useMemo<React.ReactNode>(() => {
        return <UnauthenticatedRootView />;
    }, []);

    return (
        <BrowserRouter>
            <GlobalAnchorHandler />
            <LoggedInTemplate>
                {renderLoggedIn}
            </LoggedInTemplate>

            <LoggedOutTemplate>
                {renderLoggedOut}
            </LoggedOutTemplate>
        </BrowserRouter>
    );
};

function GlobalAnchorHandler() {
    const navigate = useNavigate();
    const localAnchorHandler = setupGlobalAnchorClickHandler();
    useEffect(() => {
        const onLocalNavigation = ({ path, hash }: EventHandlerModel<typeof localAnchorHandler>) => {
            navigate({ pathname: path, hash: hash });
        };

        localAnchorHandler.on(onLocalNavigation);
        return () => localAnchorHandler.off(onLocalNavigation);
    }, [navigate, localAnchorHandler]);

    return <></>;
}

function NotFound() {
    const { pathname } = useLocation();
    const labels = useLabels();
    const navigate = useNavigate();

    return <PageLayout>
        <Hero
            mainContent={<h1>404: {pathname}</h1>}
            sideContent={<Button
                label={labels.goBack}
                onClick={() => navigate(AppRoute.root)}
            />}
        />
    </PageLayout>;
}

export default Routing;