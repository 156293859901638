import NewChatWindow from '../../elements/new-chat-window/NewChatWindow';
import ChatWindow from '../../elements/chat-window/ChatWindow';
import { memo } from 'react';
import { ChatViewLayoutProps } from '../../layouts/chat-view-layout/ChatViewLayout';

const ChatView = memo((props: ChatViewLayoutProps) => {
    if (props.newPrompt) return <NewChatWindow {...props} />;
    return <ChatWindow {...props} />;
});

export default ChatView;