import React, { useCallback, useMemo, useState } from 'react';
import useInfo from '../../hooks/useInfo';
import ListItemContent from '../../ui/list/ListItemContent';
import Button, { ButtonIconPosition, ButtonThemes } from '../../ui/button/Button';
import useLabels from '../../hooks/useLabels';
import { fallbackIcon, PERSONAS_ICON_MAP } from '../personas-catalog/PersonasCatalog';
import { ChatInputModel } from '../chat-input';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../router/Routing';
import { Icon } from '../../ui/icon/Icon';
import { Icons } from '../../ui/icon/icons/material';
import { advancedAssistantKey } from '../../constants/consts';
import { sortItemsBy } from '../../helpers/listsHelpers';
import { useAdaptive } from '../../contexts/adaptive/AdaptiveContext';
import { SortableList } from '../../ui/sortable-list/SortableList';
import { useLayout } from '../../contexts/layout/LayoutContext';
import { getClassNames } from '../../helpers/classHelpers';
import { ListItemContentProps } from '../../ui/list/List';
import { IconTheme } from '../../ui/icon/Icon.types';
import { SvgIcons } from '../../ui/icon/icons/svg';
import { ALWAYS_PINED_PERSONAS } from '../../contexts/adaptive/useCatalogFavorites';
import styles from './PersonaPreferences.module.scss';

type Props = {
    persona?: string;
    newPromptOrigin?: string;
    showActivePersona?: boolean;
    onModelMerge: (sessionId: string, inputModel: Partial<ChatInputModel>) => void;
};

// Needed because DragDropContext requires a unique id for each item and advanced assistant key is empty string thus library assumes it's not provided.
const advancedAssistantMockId = 'advanced-assistant-mock';

const PersonaPreferences: React.FC<Props> = ({ persona, newPromptOrigin, showActivePersona, onModelMerge }) => {
    const labels = useLabels();
    const navigate = useNavigate();
    const { personaOptions } = useInfo();
    const { hideLeftSidebar } = useLayout();
    const { catalogFavorites } = useAdaptive();

    const [order, setOrder] = useState<string[]>(catalogFavorites?.personas);

    const personas = useMemo(() => personaOptions?.map((item) => ({
        ...item,
        id: item.key,
        icon: (item).iconName ?? PERSONAS_ICON_MAP?.[item.key] ?? fallbackIcon,
        order: order.indexOf(item.key) ?? -1,
        onClick: () => {
            navigate(item.routeKey ? "/" + item.routeKey : AppRoute.chat);
            hideLeftSidebar();
        }
    })), [hideLeftSidebar, navigate, order, personaOptions]);

    const handleRemoveFromFavorites = useCallback((persona: { key: string; }) => {
        catalogFavorites.setFavorite(persona.key);
    }, [catalogFavorites]);

    const handleSortOrderChange = useCallback((newSortOrder: string[]) => {
        setOrder(newSortOrder);
        catalogFavorites.setFavorites(newSortOrder);
    }, [catalogFavorites]);

    const listItems = useMemo((): ListItemContentProps[] => {
        const personaKey = newPromptOrigin ?? persona;
        return sortItemsBy(personas, 'order')?.filter(persona => catalogFavorites?.personas?.includes(persona.key)).map((persona) => ({
            ...persona,
            className: showActivePersona && [persona.key, persona.routeKey].includes(personaKey) ? styles['selected'] : '',
            hoverIconClassName: getClassNames([(ALWAYS_PINED_PERSONAS.includes(persona.key) || catalogFavorites.disallowPinning) && styles.hidden, styles['pinning-icon']]),
            hoverIcon: <Icon.Base
                title=''
                iconName={Icons.star}
                theme={IconTheme.accent}
                className={getClassNames([styles['chat-icon']])}
            />,
            onHoverIconClick: () => handleRemoveFromFavorites(persona)
        }));
    }, [catalogFavorites.disallowPinning, catalogFavorites?.personas, handleRemoveFromFavorites, newPromptOrigin, persona, personas, showActivePersona]);

    return (
        <div className={styles['persona-preferences']}>
            <div className={styles["list"]}>
                <ul>
                    <SortableList
                        items={listItems}
                        getItemId={(item) => item.id || advancedAssistantMockId}
                        renderItem={({ ref, item, isActive, isDragged, props, handleProps }) => {
                            return (
                                <>
                                    <ListItemContent
                                        ref={ref}
                                        item={item}
                                        props={props}
                                        className={getClassNames([styles["list-item"], styles.none, isActive && styles.isActive, isDragged && styles.isDragged])}
                                        children={<div className={getClassNames([styles.handle, catalogFavorites?.disallowPinning && styles.hidden])}{...handleProps}>
                                            <Icon.Base title={''} className={styles.icon} iconName={Icons.dragIndicator} isClickable />
                                        </div>}
                                    />
                                </>
                            );
                        }}
                        onSort={(oldIndex, newIndex) => {
                            const newItems = listItems.slice();
                            newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
                            handleSortOrderChange(newItems.map(({ id }) => {
                                if (id === advancedAssistantMockId) return advancedAssistantKey;
                                return id;
                            }));
                        }}
                    />
                </ul>

                <div className={styles['open-catalog']}>
                    <Button
                        theme={ButtonThemes.customTextLightDarkToDark}
                        // Add padding to normalize Icons and SvgIcons spacing, so History & PersonaPreferences CTAs would look same.
                        icon={<Icon.Svg iconName={SvgIcons.Storefront} style={{ padding: '0 2' }} theme={IconTheme.inherit} size={22} isClickable title='' />}
                        iconPosition={ButtonIconPosition.beginning}
                        onClick={() => {
                            navigate(AppRoute.catalog);
                            hideLeftSidebar();
                        }}
                        children={labels.personasCatalogExplore}
                    />
                </div>

            </div>
        </div>

    );
};

export default PersonaPreferences;