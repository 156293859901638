import useStateRef from '../../hooks/useStateRef';
import EventHandler from '../../services/EventHandler';
import { useCallback, useEffect } from 'react';
import { BackendEvent, BackendEventTypes, DocumentEvent, DocumentEventTypes } from '../../models/BackendEvents';
import { useChat } from '../../contexts/chat/ChatContext';

const WHITELISTED_DOCUMENTS_EVENTS = [DocumentEventTypes.created, DocumentEventTypes.processed, DocumentEventTypes.failed] as BackendEventTypes[];

const getRelevantEvents = <T extends { type: BackendEventTypes; }[]>(events: T, whitelistedEvents: string[]) => {
    const filteredEvents = events.filter((event) => whitelistedEvents.includes(event.type));
    return (filteredEvents ?? []) as BackendEvent[];
};

const useDocumentEvents = () => {
    const { events: { document: documentEvents } } = useChat();
    const [events, setEvents] = useStateRef<Record<string, DocumentEventTypes>>({});

    const onDocumentEvent = useCallback((model: string) => {
        const events = getRelevantEvents([JSON.parse(model)], WHITELISTED_DOCUMENTS_EVENTS);
        if (!events?.length) return;

        events.forEach((event) => {
            const documentEvent = event as DocumentEvent;
            const handler = (prev: Record<string, DocumentEventTypes>) => ({ ...prev, [documentEvent.document_id]: event.type as DocumentEventTypes });
            setEvents(handler);
        });
    }, [setEvents]);

    useEffect(() => {
        const events = documentEvents as unknown as EventHandler<string>;
        events.on(onDocumentEvent);
        return () => events.off(onDocumentEvent);
    }, [documentEvents, onDocumentEvent]);


    return { events };
};

export default useDocumentEvents;