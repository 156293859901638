import EnvConfigHelper from '../helpers/envConfigHelper';
import { mockGetInfoResponse, mockGetTermsResponse } from '../data/mockData';
import { UserPreferences } from '../models/types';
import { IAppInfoService } from './IAppInfoService';

const delay = <R>(response: R) => new Promise<R>((resolve) => setTimeout(() => resolve(response), 500));

class MockAppInfoService implements IAppInfoService {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    constructor(public getAccessToken: () => Promise<string | undefined>) {}

    getInfo = async () => {
        this.getAccessToken();
        return delay(mockGetInfoResponse);
    };

    getTerms = async () => {
        return delay(mockGetTermsResponse);
    };

    getPersonaVersionReleaseNotes = async (persona: string, version: string) => {
        return delay("MOCK");
    };

    getUserPreferences = async () => ({ personas: [], preventPersonasPinningOnChatCreation: [] });

    setUserPreferences = async (preferences: UserPreferences) => {};
}

export default MockAppInfoService;