import { type PropsWithChildren, useContext, useMemo, } from 'react';
import { AuthContext } from '../auth/AuthContext';
import AppInfoProvider from './AppInfoContext';
import AppInfoService from '../../services/AppInfoService';

const AppInfoServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { getAccessToken } = useContext(AuthContext);

    const service = useMemo(() => new AppInfoService(getAccessToken), [getAccessToken]);

    return <AppInfoProvider service={service}>
        {children}
    </AppInfoProvider>;
};

export default AppInfoServiceProvider;