// Custom
export { ReactComponent as User } from '../../../shared/assets/icons/user.svg';
export { ReactComponent as PimPersona } from '../../../shared/assets/icons/dcs-pim-persona.svg';
export { ReactComponent as Bot } from '../../../shared/assets/icons/bot-default.svg';
export { ReactComponent as Bolt } from '../../../shared/assets/icons/bolt.svg';
export { ReactComponent as InstantMix } from '../../../shared/assets/icons/instant_mix.svg';
export { ReactComponent as Robot } from '../../../shared/assets/icons/robot.svg';
export { ReactComponent as Storefront } from '../../../shared/assets/icons/storefront.svg';

export enum SvgIcons {
    User = 'User',
    PimPersona = 'PimPersona',
    Bot = 'Bot',
    Bolt = 'Bolt',
    InstantMix = 'InstantMix',
    Robot = 'Robot',
    Storefront = 'Storefront',
}

