import useLabels from './useLabels';
import { useCallback, useMemo, } from 'react';
import { rawLlmToUI } from '../helpers/transformHelpers';
import { useAppInfo } from '../contexts/app-info/AppInfoContext';
import { sortItemsBy } from '../helpers/listsHelpers';
import { Icons } from '../ui/icon/icons/material';
import { SvgIcons } from '../ui/icon/icons/svg';
import { PersonaOption } from '../models/types';
import { advancedAssistantKey, advancedAssistantRoute, pimAssistantKey, pimAssistantRoute } from '../constants/consts';

type ExtendedPersonaOption = (PersonaOption & { iconName?: SvgIcons | Icons; });

export default function useInfo() {
    const labels = useLabels();
    const { llms, personas, access, documents } = useAppInfo();

    const llmOptions = useMemo(() => {
        if (!llms) return [];
        return llms
            .map(rawLlmToUI)
            .sort((a, b) => a.sort - b.sort);
    }, [llms]);

    const personaOptions = useMemo(() => {
        if (!personas?.length) return [];

        let items = [...sortItemsBy(personas, "sort"), {
            key: advancedAssistantKey,
            routeKey: advancedAssistantRoute,
            title: labels.advancedTitle,
            description: labels.advancedDescription,
            documents: {
                enabled: true,
            },
        }].map((persona, index) => {
            // Assign routeKey to PIM assistant. 
            // Before it was using 'dcs' route as a mean to select persona via URI - while other personas wasn't having this option. 
            // Thus here we're making sure that the URI is pointing to legacy route path instead of persona key.
            if (persona.key === pimAssistantKey) persona.routeKey = pimAssistantRoute;
            persona.routeKey = persona?.routeKey || persona.key;

            return {
                ...persona,
                documents: {
                    ...persona.documents,
                    ...documents,
                    enabled: documents?.enabled || persona.documents?.enabled,
                },
            };
        }) as ExtendedPersonaOption[];

        return items;
    }, [documents, labels.advancedDescription, labels.advancedTitle, personas]);

    const allowedPersonaOptions = useMemo(() => {
        return personaOptions.filter(p => !p.key || !!access?.personas?.[p.key]);
    }, [personaOptions, access]);

    const getPersonaFromKey = useCallback((key: string) => personaOptions?.find(p => p.key === key), [personaOptions]);

    const getPersonaFromRouteKey = useCallback((key: string) => personaOptions?.find(p => p?.routeKey === key), [personaOptions]);

    const getLlmFromKey = useCallback((key: string) => llms?.find(llm => llm.key === key), [llms]);

    const getLlmLabelFromKey = useCallback((key: string) => getLlmFromKey(key)?.title || key, [getLlmFromKey]);

    const getPersonaLabelFromKey = useCallback((key: string) => getPersonaFromKey(key)?.title ?? key, [getPersonaFromKey]);

    return {
        llmOptions,
        personaOptions,
        allowedPersonaOptions,
        getPersonaFromKey,
        getPersonaFromRouteKey,
        getLlmFromKey,
        getLlmLabelFromKey,
        getPersonaLabelFromKey
    };
}