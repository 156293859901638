import { generateUniqueId } from "@danfoss/mosaic-core";
import { createContext, useContext, useMemo } from "react";

export type ModalType = {
    type: 'modal',
    headline: string,
    notification?: Omit<NotificationType, 'type'>;
    onClose?: () => void;
};

export type NotificationType = {
    type: 'notification',
    headline: string,
    description?: string,
    details?: Record<string, unknown>;
};

export type NotificationsType = {
    type: 'notifications',
    notifications: NotificationType[];
};

export type ErrorStackType = Record<string, ModalType | NotificationType | NotificationsType>;

export type ErrorHandlerContextType = {
    errorStack: ErrorStackType;
    registerError: (error: ErrorStackType) => void;
    removeError: (errorId: string) => void;
    getError: (errorId?: string) => ErrorStackType[keyof ErrorStackType];
};

export const ErrorContext = createContext<ErrorHandlerContextType | undefined>(undefined);

export const useErrorHandlerContext = (): ErrorHandlerContextType & { errorId: string; } => {
    const context = useContext(ErrorContext);
    const errorId = useMemo(() => generateUniqueId(), []);

    if (!context) {
        throw new Error('This component must be used within a <ErrorHandler> component.');
    }
    return { ...context, errorId };
};
