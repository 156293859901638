import useLabels from '../../hooks/useLabels';
import ChatWelcome from '../chat-welcome/ChatWelcome';
import ChatRecommendations from '../chat-recommendations/ChatRecommendations';
import ChatWindowHeader from '../chat-window-header/ChatWindowHeader';
import useInfo from '../../hooks/useInfo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getChatItemRoute } from '../../router/Routing';
import { defaultPersonaKey, defaultTemperature, pimAssistantKey } from '../../constants/consts';
import { useChat } from '../../contexts/chat/ChatContext';
import { ChatInput, ChatInputModel } from '../chat-input';
import { CHAT_INPUT_ERROR_ID } from '../chat-input/ChatInput';
import { validateInputModelSelection } from '../../views/chat-view/usePersistentInputState';
import { TestIds } from '../../mocks/ids';
import { ObjectTypes } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import { useErrorHandlerContext } from '../../contexts/error-handler/ErrorContext';
import { ChatViewLayoutProps } from '../../layouts/chat-view-layout/ChatViewLayout';
import { useAdaptive } from '../../contexts/adaptive/AdaptiveContext';
import style from './NewChatWindow.module.scss';

const NewChatWindow: React.FC<ChatViewLayoutProps> = ({ onOpenSidebar, newPromptOrigin, onReloadChats }) => {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { catalogFavorites } = useAdaptive();
    const { getPersonaFromKey, getPersonaFromRouteKey } = useInfo();
    const { createChat, persistent } = useChat();
    const { llmOptions, allowedPersonaOptions } = useInfo();

    const { registerError, removeError } = useErrorHandlerContext();

    const promptOrigin = useMemo(() => newPromptOrigin!, [newPromptOrigin]);

    const defaultLlm = useMemo(() => llmOptions?.[0]?.value, [llmOptions]);
    const { lastInputState, getInput, setInput, mergeInput, setAutoSubmit } = persistent;

    const personaFromRoute = useMemo(() => {
        const persona = getPersonaFromRouteKey(promptOrigin)?.key;
        if (persona !== undefined) return { persona };
    }, [getPersonaFromRouteKey, promptOrigin]);

    const defaultValues = useMemo(() => {
        return ({
            ...getInput(promptOrigin, { message: '', llm: defaultLlm }),
            ...personaFromRoute,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastInputState, getInput, personaFromRoute, promptOrigin]);

    const [inputModel, setInputModel] = useState<ChatInputModel>(defaultValues);
    const [isProcessingRecommendation, setIsProcessingRecommendation] = useState(false);

    // Refresh input model on persona change in defaultValues
    useEffect(() => setInputModel(defaultValues), [defaultValues]);

    const isDocumentUploadEnabled = useMemo(() => getPersonaFromKey(inputModel.persona!)?.documents?.enabled ?? getPersonaFromRouteKey(newPromptOrigin!)?.documents?.enabled, [getPersonaFromKey, inputModel.persona, getPersonaFromRouteKey, newPromptOrigin]);

    // Close side bar on persona without document upload feature
    useEffect(() => {
        if (isDocumentUploadEnabled && hash.includes(ObjectTypes.documents)) return;
        navigate({ hash: '' });
    }, [hash, isDocumentUploadEnabled, navigate]);

    const onModelMerge = useCallback((model: Partial<ChatInputModel>) => {
        setInputModel(m => ({ ...m, ...model }));
        mergeInput(promptOrigin, model);
    }, [mergeInput, promptOrigin]);

    useEffect(() => {
        const { isValidSelection, isValidLlmSelection, isValidPersonaSelection } = validateInputModelSelection({ llm: inputModel.llm, persona: inputModel.persona }, llmOptions, allowedPersonaOptions);
        if (isValidSelection) return;
        if (!isValidLlmSelection) onModelMerge({ llm: defaultLlm });
        if (!isValidPersonaSelection) onModelMerge({ llm: catalogFavorites.firstFavorite });
    }, [inputModel.llm, inputModel.persona, llmOptions, allowedPersonaOptions, defaultLlm, onModelMerge, catalogFavorites.firstFavorite]);

    const labels = useLabels();
    const l = useMemo(() => {
        return {
            chatTitle: labels.newChatTitle,
            placeholder: labels.inputPlaceholder,
            disclaimerNote: labels.disclaimerNote,
        };
    }, [labels]);

    const onSendPromptHandler = useCallback(async (alternativeMessage?: string) => {
        const message = alternativeMessage ?? inputModel.message ?? '';
        const documents = isDocumentUploadEnabled ? inputModel.documents : undefined;

        const { session_id, documents: responseDocuments } = await createChat(
            message,
            inputModel.llm || '',
            inputModel.temperature ?? defaultTemperature,
            inputModel.persona,
            documents
        );

        // Remove the persisted input model of the new session and assign the message to the input model of the newly created session
        mergeInput(promptOrigin, { message: '', ...((session_id && isDocumentUploadEnabled) ? { documents: inputModel.documents?.filter(x => !responseDocuments?.includes(x)) } : {}) });
        setInput(session_id, { message, documents });
        setAutoSubmit(session_id);

        navigate({ pathname: getChatItemRoute(session_id), hash });
        onReloadChats?.(true);
    }, [inputModel, isDocumentUploadEnabled, promptOrigin, createChat, mergeInput, setInput, setAutoSubmit, navigate, hash, onReloadChats]);

    const onSelectRecommendation = useCallback(async (recommendation: string) => {
        removeError(CHAT_INPUT_ERROR_ID);

        try {
            setIsProcessingRecommendation(true);
            await onSendPromptHandler(recommendation);
            catalogFavorites.setAsFavoriteOnInitialPrompt(inputModel.persona!);
        }
        catch (e: any) {
            registerError({ [CHAT_INPUT_ERROR_ID]: { type: 'modal', headline: 'errorHeadline', notification: { headline: labels.submitFailed, details: e } } });
        }
    }, [catalogFavorites, inputModel.persona, labels.submitFailed, onSendPromptHandler, registerError, removeError]);


    const currentPersona = useMemo(() => {
        return allowedPersonaOptions.find(x => x.key === inputModel.persona);
    }, [allowedPersonaOptions, inputModel.persona]);

    const recommendations = useMemo(() => {
        return {
            '': [
                'Create an Instagram post about Product',
                'Write a LinkedIn status about',
                'What are the specs for a Facebook post?',
                'What are the specs for a X post?'
            ],
            [defaultPersonaKey]: [
                'Create an Instagram post about Product',
                'Write a LinkedIn status about',
                'What are the specs for a Facebook post?',
            ],
            [pimAssistantKey]: [
                'Create an Instagram post about Product',
                'Write a LinkedIn status about',
            ]
        } as Record<string, string[]>;
    }, []);

    return (<>
        <div data-testid={TestIds.chatWindow} className={style['chat-window']}>
            <ChatWindowHeader
                initialTitle={l.chatTitle}
                onBurgerClick={onOpenSidebar}
            />
            <div className={style['chat-window-wrapper']}>
                <div className={style['new-chat-messages']}>
                    <ChatWelcome persona={currentPersona} />
                    <ChatRecommendations
                        isDisabled={isProcessingRecommendation}
                        recommendations={currentPersona?.key !== undefined ? recommendations[currentPersona?.key] : undefined}
                        onClick={onSelectRecommendation}
                    />
                </div>


                <div className={style['input-container']}>
                    <ChatInput
                        displayNewChatOptions
                        chatId={promptOrigin}
                        model={inputModel}
                        placeholder={l.placeholder}
                        inputNote={l.disclaimerNote}

                        personaOptions={allowedPersonaOptions}
                        llmOptions={llmOptions}
                        onModelMerge={onModelMerge}
                        onSubmitPrompt={onSendPromptHandler}
                    />
                </div>
            </div>
        </div>
    </>);
};

export default NewChatWindow;