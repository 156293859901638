import useHistory from './useChatsHistory';
import useCatalogFavorites from './useCatalogFavorites';
import useChatInfo from './useChatInfo';
import useRibbonMessage from './useRibbonMessage';
import { PropsWithChildren, createContext, useContext } from 'react';

export type IAdaptiveProvider = {} & ReturnType<typeof useChatInfo> & ReturnType<typeof useHistory> & ReturnType<typeof useCatalogFavorites & ReturnType<typeof useRibbonMessage>>;

const defaultAdaptiveContextValues: IAdaptiveProvider = {
    chatInfo: {
        persona: '',
        setPersona: () => { throw new Error('Function not implemented.'); },
    },
    chatHistory: {
        items: [],
        set: () => { throw new Error('Function not implemented.'); },
        reload: () => { throw new Error('Function not implemented.'); },
    },
    catalogFavorites: {
        personas: [],
        firstFavorite: '',
        disallowPinning: false,
        setFavorite: () => { throw new Error('Function not implemented.'); },
        setFavorites: () => { throw new Error('Function not implemented.'); },

        preventPersonasPinningOnChatCreation: [],
        setPreventPersonasPinningOnChatCreation: () => { throw new Error('Function not implemented.'); },
        setAsFavoriteOnInitialPrompt: () => { throw new Error('Function not implemented.'); },
    }
};

export const AdaptiveContext = createContext<IAdaptiveProvider>(defaultAdaptiveContextValues);

const AdaptiveProvider = (props: PropsWithChildren<IAdaptiveProvider>) => {
    return (
        <AdaptiveContext.Provider value={props}>
            {props.children}
        </AdaptiveContext.Provider>
    );
};

export const useAdaptive = () => useContext(AdaptiveContext) as IAdaptiveProvider;

export default AdaptiveProvider;