import { useCallback, useEffect, useMemo, useState } from 'react';
import { advancedAssistantKey, advancedAssistantRoute, defaultPersonaKey } from '../../constants/consts';
import { useAppInfo } from '../app-info/AppInfoContext';
import { UserPreferences } from '../../models/types';
import { isString } from '../../helpers/typeHelpers';

export const ALWAYS_PINED_PERSONAS: string[] = [];
export const INITIALLY_PINED_PERSONAS: string[] = [defaultPersonaKey];

const useCatalogFavorites = () => {
    const { setUserPreferences, getUserPreferences } = useAppInfo();
    const [state, setState] = useState<UserPreferences>({ personas: [], preventPersonasPinningOnChatCreation: [] });

    // Load user preferences & set default persona
    // TODO: Load from API rather than local storage. 
    useEffect(() => {
        (async () => {
            const preferences = await getUserPreferences();
            if (ALWAYS_PINED_PERSONAS.length) {
                ALWAYS_PINED_PERSONAS.forEach(key => !preferences.personas?.includes(key) && preferences.personas?.unshift(key));
            }
            if (!preferences.personas.length && INITIALLY_PINED_PERSONAS.length) {
                INITIALLY_PINED_PERSONAS.forEach(key => !preferences.personas?.includes(key) && preferences.personas?.unshift(key));
            }
            setState({ ...preferences });
        })();
    }, [getUserPreferences]);

    const processPreventPersonasPinningOnChatCreation = useCallback((prev: UserPreferences, persona: string) => {
        return prev?.preventPersonasPinningOnChatCreation?.includes(persona) ? prev.preventPersonasPinningOnChatCreation : [...(prev?.preventPersonasPinningOnChatCreation || []), persona];
    }, []);

    const handlePreventPersonasPinningOnChatCreation = useCallback((persona: string) => {
        setState(prev => ({ ...prev, preventPersonasPinningOnChatCreation: processPreventPersonasPinningOnChatCreation(prev, persona) }));
    }, [processPreventPersonasPinningOnChatCreation]);

    const handleSetFavorite = useCallback((persona: string, force = true) => {
        setState(prev => {
            let personas: string[];
            let preventPersonasPinningOnChatCreation: string[];

            if (prev.personas?.includes(persona)) {
                personas = force ? prev.personas.filter(f => f !== persona) : prev.personas;
                preventPersonasPinningOnChatCreation = processPreventPersonasPinningOnChatCreation(prev, persona);
                setUserPreferences({ personas, preventPersonasPinningOnChatCreation });
            }
            else {
                personas = [persona, ...prev.personas];
                preventPersonasPinningOnChatCreation = prev?.preventPersonasPinningOnChatCreation;
                setUserPreferences({ personas, preventPersonasPinningOnChatCreation });
            }

            return { personas, preventPersonasPinningOnChatCreation };
        });
    }, [processPreventPersonasPinningOnChatCreation, setUserPreferences]);

    const handleSetFavorites = useCallback((personas: string[]) => {
        setState(prev => {
            setUserPreferences({ personas, preventPersonasPinningOnChatCreation: prev.preventPersonasPinningOnChatCreation });
            return { ...prev, personas };
        });
    }, [setUserPreferences]);

    const handleSetAsFavoriteOnInitialPrompt = (persona: string) => {
        if (!isString(persona) || (state?.personas?.includes(persona) || state?.preventPersonasPinningOnChatCreation?.includes(persona))) return;
        handleSetFavorite(persona, false);
    };

    const firstFavorite = useMemo(() => {
        const value = state.personas?.[0];
        if (value === advancedAssistantKey) return advancedAssistantRoute;
        return value;
    }, [state.personas]);

    return {
        catalogFavorites: {
            ...state,
            firstFavorite,
            /** Disallow pinning if there is only one persona that is pinned */
            disallowPinning: state.personas?.length === 1,
            setFavorite: handleSetFavorite,
            setFavorites: handleSetFavorites,
            /** This function is relevant for use-case of marking persona as favorite automatically once first prompt is submitted  */
            setAsFavoriteOnInitialPrompt: handleSetAsFavoriteOnInitialPrompt,
            setPreventPersonasPinningOnChatCreation: handlePreventPersonasPinningOnChatCreation
        }
    };
};

export default useCatalogFavorites;