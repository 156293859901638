import { CustomSelectOptionBaseType, CustomSelectOptionType, SelectChangeEventArgsType } from '@danfoss/mosaic-core';
import { DfSelectCustomEvent } from '@danfoss/mosaic-core/loader';
import { DfSelect } from '@danfoss/mosaic-react';
import { useCallback } from 'react';
import { BaseUIType } from '../../models/UITypes';
import { isNumber } from '../../helpers/typeHelpers';

export type SelectEventType = DfSelectCustomEvent<SelectChangeEventArgsType<false, false>>;

type Props = {
    /** Sets default selection value */
    selectedOptionIndex?: number;
    options: CustomSelectOptionType[];
    onChange: (index?: CustomSelectOptionBaseType) => void;
    value?: string;
    isDisabled?: boolean;
    showSelectedOptions?: boolean;
};

const Select = ({ testId, value, isDisabled, selectedOptionIndex, showSelectedOptions = false, options, onChange }: BaseUIType & Props) => {
    const handleChange = useCallback((e: SelectEventType) => onChange(e.detail.value as CustomSelectOptionBaseType), [onChange]);

    return (
        <DfSelect
            options={options}
            data-testid={testId}
            disabled={isDisabled}
            showSelectedOptions={showSelectedOptions}
            value={value}
            defaultSelectValue={isNumber(selectedOptionIndex) ? options?.[selectedOptionIndex] : undefined}
            onSelectChange={handleChange}
        />
    );
};

export default Select;