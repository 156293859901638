import { PropsWithChildren } from 'react';
import { AdaptiveContext, IAdaptiveProvider } from './AdaptiveContext';
import { mockGetChatsResponse } from '../../data/mockData';

const MockAdaptiveProviderValue: IAdaptiveProvider = {
    chatInfo: {
        persona: '',
        setPersona: () => {},
    },
    chatHistory: {
        items: mockGetChatsResponse,
        set: () => {},
        reload: () => Promise.resolve(),
    },
    catalogFavorites: {
        personas: [''],
        firstFavorite: '',
        disallowPinning: false,
        setFavorite: (favorite: string) => {},
        setFavorites: () => {},

        preventPersonasPinningOnChatCreation: [],
        setPreventPersonasPinningOnChatCreation: () => {},
        setAsFavoriteOnInitialPrompt: () => {},
    }
};

const MockAdaptiveProvider: React.FC<PropsWithChildren> = ({ children }) => {
    return <AdaptiveContext.Provider value={MockAdaptiveProviderValue}>
        {children}
    </AdaptiveContext.Provider>;
};

export default MockAdaptiveProvider;