import React, { useCallback, useMemo, useState } from 'react';
import Checkbox from '../../../../../../../checkbox/Checkbox';
import Prompt, { PromptProps } from '../../../../../../../prompt/Prompt';
import useLabels from '../../../../../../../../hooks/useLabels';
import useInfo from '../../../../../../../../hooks/useInfo';
import Select from '../../../../../../../select/Select';
import InputLabel from '../../../../../../../input-label/InputLabel';
import useStateRef from '../../../../../../../../hooks/useStateRef';
import { getClassNames } from '../../../../../../../../helpers/classHelpers';
import { Icon } from '../../../../../../../icon/Icon';
import { IconTheme } from '../../../../../../../icon/Icon.types';
import { Icons } from '../../../../../../../icon/icons/material';
import { useParams } from 'react-router-dom';
import { TestIds } from '../../../../../../../../mocks/ids';
import { useAdaptive } from '../../../../../../../../contexts/adaptive/AdaptiveContext';
import { isString } from '../../../../../../../../helpers/typeHelpers';
import styles from './DocumentListActions.module.scss';

type Props = {
    items: any[],
    selectedIds: string[];
    onSelectAll: () => void;
    onDeselectAll: () => void;
    onEdit: (documentData: { fileId: string, name: string; description: string; }) => void;
    onDownload: () => void;
    onDelete: () => void;
    onRefresh: () => void;
    onCopy: (selectedIds: string[], assistant: string) => void;
};

const DocumentListActions = ({ items, selectedIds, onRefresh, onSelectAll, onDeselectAll, onEdit, onCopy, onDownload, onDelete }: Props) => {
    const labels = useLabels();
    const { chatId } = useParams();
    const { chatInfo } = useAdaptive();
    const { allowedPersonaOptions } = useInfo();
    const [selectedAssistant, setSelectedAssistant, selectedAssistantRef] = useStateRef<string | undefined>(chatInfo.persona);
    const allowedOptions = useMemo(() => allowedPersonaOptions.filter(x => x.documents?.enabled).map(({ title: label, key: value }) => ({ value, label })), [allowedPersonaOptions]);

    const [promptProps, setPromptProps] = useState<Pick<PromptProps, 'headline' | 'body' | 'header' | 'primaryButton' | 'secondaryButton' | 'buttonContainerClassNames'>>();

    const isInNewChat = useMemo(() => !chatId, [chatId]);

    const resetPromptProps = useCallback(() => setPromptProps(undefined), []);

    const handleDownload = useCallback(() => {
        onDownload();
    }, [onDownload]);

    const confirmDelete = useCallback(() => {
        onDelete();
        resetPromptProps();
    }, [onDelete, resetPromptProps]);

    const confirmCopy = useCallback(() => {
        // Due to issues of reactivity in modal, it is not possible to react on proper state changes, thus using ref to access the latest value of selectedAssistant
        const selectedAssistant = selectedAssistantRef.current;
        if (isString(selectedAssistant)) onCopy(selectedIds, selectedAssistant);
        resetPromptProps();
    }, [selectedAssistantRef, onCopy, selectedIds, resetPromptProps]);

    const renderSelectedNames = useMemo(() => items.filter(item => selectedIds.includes(item.id)).map(item => item.name).join(', '), [items, selectedIds]);

    const deleteModalProps = useMemo(() => ({
        headline: labels.deleteDocumentModalTitle,
        header: labels.deleteDocumentConfirmationText,
        body: renderSelectedNames,
        primaryButton: { label: labels.deleteDocumentDeleteButton, onClick: confirmDelete },
        secondaryButton: { label: labels.deleteDocumentCancelButton },
    }), [confirmDelete, renderSelectedNames, labels]);

    const renderSelectAssistant = useMemo(() => {
        return <div className={styles.select}>
            <InputLabel
                label={labels.copySelectChatAssistant}
                className={styles['select-label']}
            />
            {allowedOptions.length > 1 && <Select
                showSelectedOptions
                options={allowedOptions}
                selectedOptionIndex={allowedOptions.findIndex(option => option.value === selectedAssistant)}
                onChange={(option) => setSelectedAssistant(option?.value as string)}
            />}
        </div>;
    }, [allowedOptions, labels.copySelectChatAssistant, selectedAssistant, setSelectedAssistant]);

    const copyModalProps = useMemo(() => ({
        headline: labels.copyToNewChatHeadline,
        header: labels.copyToNewChatDescription,
        body: <div className={styles.content}>
            {renderSelectedNames}
            {renderSelectAssistant}
        </div>,
        primaryButton: { label: labels.copyToNewChatCta, onClick: confirmCopy },
        secondaryButton: { label: labels.copyToNewChatCancelCta },
        buttonContainerClassNames: styles['ctas']
    }), [labels.copyToNewChatHeadline, labels.copyToNewChatDescription, labels.copyToNewChatCta, labels.copyToNewChatCancelCta, renderSelectedNames, renderSelectAssistant, confirmCopy]);


    const handleRefresh = useCallback(() => onRefresh(), [onRefresh]);

    const handleDelete = useCallback(() => {
        if (!selectedIds.length) return console.warn('Document not found, cannot delete');
        setPromptProps(deleteModalProps);
    }, [deleteModalProps, selectedIds]);

    const handleCopy = useCallback(() => {
        if (!selectedIds.length) return console.warn('Document not found, cannot copy');
        setPromptProps(copyModalProps);
    }, [copyModalProps, selectedIds.length]);

    const handleEdit = useCallback(() => {
        if (selectedIds.length !== 1) return console.warn('No documents selected to edit');

        const selectedDocument = items.find(item => item.id === selectedIds[0]);
        if (!selectedDocument) return console.warn('Document not found, cannot edit');

        onEdit({
            fileId: selectedDocument.id,
            name: selectedDocument.name,
            description: selectedDocument.description
        });
    }, [items, selectedIds, onEdit]);

    const isAllSelected = useMemo(() => items.length === selectedIds.length, [items.length, selectedIds.length]);

    const actionsMap = useMemo(() => [
        {
            label: labels.docListDownload,
            iconName: Icons.download,
            isDisabled: selectedIds.length !== 1,
            onClick: handleDownload,
            testId: TestIds.downloadButton
        },
        {
            label: labels.docListDelete,
            iconName: Icons.delete,
            isDisabled: selectedIds.length < 1,
            onClick: handleDelete,
            testId: TestIds.deleteButton
        },
        {
            label: labels.docListEdit,
            iconName: Icons.edit,
            isDisabled: selectedIds.length !== 1,
            onClick: handleEdit,
            testId: TestIds.editButton
        },
        !isInNewChat ? {
            label: labels.docListCopy,
            iconName: Icons.copy,
            isDisabled: selectedIds.length === 0,
            onClick: handleCopy,
            testId: TestIds.copyButton
        } : undefined,
        {
            label: '',
            iconName: Icons.refresh,
            onClick: handleRefresh,
            testId: TestIds.refreshButton
        }
        // TODO add responsive menu to accommodate the all items in this list
    ].filter(Boolean) as ListActionLabelProps[], [labels.docListDownload, labels.docListDelete, labels.docListEdit, labels.docListCopy, selectedIds.length, handleDownload, handleDelete, handleEdit, isInNewChat, handleCopy, handleRefresh]);

    return (
        <div className={styles['actions-container']}>
            <Checkbox isChecked={isAllSelected} onChange={isAllSelected ? onDeselectAll : onSelectAll} />
            <div className={styles.actions}>
                {actionsMap?.map((props, i) => <ListActionLabel key={i} {...props} />)}
            </div>

            <Prompt
                closeOnEscape
                closeOnBackdropClick
                {...promptProps as PromptProps}
                isOpen={!!promptProps}
                onClose={resetPromptProps}
            />
        </div>
    );
};

type ListActionLabelProps = {
    iconName: Icons;
    isDisabled: boolean;
    label: string;
    title?: string;
    onClick?: () => void;
    testId: string;
};

const ListActionLabel = ({ title, iconName, isDisabled, label, testId, onClick }: ListActionLabelProps) => {
    return (
        <label data-testid={testId} className={getClassNames([isDisabled && styles['_disabled']])} onClick={isDisabled ? undefined : onClick}>
            <Icon.Base theme={IconTheme.inherit} size={18} iconName={iconName} title={title ?? ''} isClickable={!isDisabled} />
            {label}
        </label>
    );
};

export default DocumentListActions;